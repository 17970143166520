import React from "react"
import Layout from "../components/Layout"
import Background from "../components/Background"
import * as notFoundStyles from "../styles/pages/404.module.scss"

export default function NotFound() {
  return (
    <Layout page="404" bgColor="inherit">
      <div>
        <Background />
        <div className={notFoundStyles.notFound__container}>
          <div className="notFound__content">
            <h1 className={notFoundStyles.header}>404</h1>
            <span className={notFoundStyles.description}>
              Unfortunately, that page does not exist
            </span>
          </div>
        </div>
      </div>
    </Layout>
  )
}
